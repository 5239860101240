import React, { FC, PropsWithChildren, SyntheticEvent, useEffect } from 'react';

import IconChevron from '../../icons/Chevron';
import IconClose from '../../icons/Close';
import {
  ButtonClose,
  ButtonNext,
  ButtonPrev,
  Content as ModalContentSC,
  Modal as ModalSC,
} from './styles';

const Modal: FC<
  PropsWithChildren<{
    handleClose: (e: SyntheticEvent<HTMLElement>) => void;
    handleNext?: (e: SyntheticEvent<HTMLElement>) => void;
    handlePrevious?: (e: SyntheticEvent<HTMLElement>) => void;
  }>
> = function ({ children, handleClose, handleNext, handlePrevious }) {
  useEffect(() => {
    document.documentElement.classList.add('is-clipped');

    return () => {
      document.documentElement.classList.remove('is-clipped');
    };
  }, []);

  return (
    <ModalSC>
      <div
        className="modal-background"
        onClick={handleClose}
        role="presentation"
      />
      {handlePrevious && (
        <ButtonPrev onClick={handlePrevious}>
          <IconChevron />
          <span className="text">Précédent</span>
        </ButtonPrev>
      )}
      {handleNext && (
        <ButtonNext onClick={handleNext}>
          <IconChevron />
          <span className="text">Suivant</span>
        </ButtonNext>
      )}
      <ButtonClose onClick={handleClose}>
        <IconClose />
        <span className="text">Fermer</span>
      </ButtonClose>
      <ModalContentSC>
        <div className="modal-content">{children}</div>
      </ModalContentSC>
    </ModalSC>
  );
};

export default Modal;
