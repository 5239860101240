import { ArticleNode } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

const Item: FC<{
  data: ArticleNode;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ data, onClick }) => (
  <div>
    {data.name}
    <button data-pathname={data.pathname} onClick={onClick} type="button">
      {` Voir l'article`}
    </button>
  </div>
);

export default Item;
