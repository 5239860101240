import styled from '@emotion/styled';
import { ArticleNode, ArticleType, DocumentType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import Modal from '../Modal';
import Post from '../Post';
import Item from './Item';

const PostsSC = styled.div``;
interface PostsProps {
  items: ArticleNode[];
  pathname: string;
  totalCount: number;
}

const Posts: FC<PostsProps> = function ({ items, pathname, totalCount }) {
  const postsRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [posts, setPosts] = useState<ArticleNode[]>([]);
  const [post, setPost] = useState<ArticleNode>();
  const [next, setNext] = useState<string>();
  const [previous, setPrevious] = useState<string>();

  const handleOnChange = async (path?: string) => {
    if (path && pathname) {
      let tmpPath = path;
      if (path.substr(path.length - 1, 1) !== '/') {
        tmpPath += '/';
      }
      const json = await window
        .fetch(`/page-data/${tmpPath}page-data.json`)
        .then(res => res.json())
        .catch(console.error);
      if (json && json.result) {
        const {
          result: { data, pageContext },
        } = json;

        if (data && data.post) {
          setPost(data.post);
          window.history.replaceState(
            { path, postId: data.post.key },
            data.post.name,
            `/${path}`,
          );
        }
        if (pageContext) {
          setNext(pageContext.next[pathname.substr(1, pathname.length - 2)]);
          setPrevious(
            pageContext.previous[pathname.substr(1, pathname.length - 2)],
          );
        }
      }
    }
  };

  const handleOnClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    if (window) {
      e.preventDefault();
      const path = e.currentTarget.getAttribute('data-pathname');
      let tmpPath = path;
      if (path && path.substr(path.length - 1, 1) !== '/') {
        tmpPath += '/';
      }
      const json = await fetch(`/page-data/${tmpPath}page-data.json`)
        .then(res => res.json())
        .catch(error => {
          console.error(error);
          setPost(undefined);
          setNext(undefined);
          setPrevious(undefined);
        });
      if (json && json.result) {
        const {
          result: { data, pageContext },
        } = json;
        if (data && data.post) {
          setPost(data.post);
          window.history.pushState(
            { path, postId: data.post.key },
            data.post.name,
            `/${path}`,
          );
        }
        if (pageContext && pathname) {
          if (pageContext.next) {
            setNext(pageContext.next[pathname.substr(1, pathname.length - 2)]);
          }

          if (pageContext.previous) {
            setPrevious(
              pageContext.previous[pathname.substr(1, pathname.length - 2)],
            );
          }
        }
      } else {
        console.error('problem json');
      }
    }
  };

  const handleClose = () => {
    setPost(undefined);
    if (window) {
      window.history.pushState({ path: pathname }, '', pathname);
    }
  };

  useEffect(() => {
    const handleOnpopstate = (event: PopStateEvent) => {
      if (event.state && event.state.key) {
        setPost(undefined);
        setTimeout(() => {
          window.scrollTo(0, scrollTop);
        }, 200);
      }
    };

    if (window) {
      window.addEventListener('popstate', handleOnpopstate);
    }

    return () => {
      if (window) {
        window.removeEventListener('popstate', handleOnpopstate);
      }
    };
  }, [scrollTop]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleScrollTop = ({ target }) => {
      setScrollTop(target.scrollingElement.scrollTop);
    };

    if (window) {
      window.addEventListener('scroll', handleScrollTop);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', handleScrollTop);
      }
    };
  }, []);

  useEffect(() => {
    setPosts(items);
  }, [items]);

  if (0 === totalCount) {
    return <p>Aucun article</p>;
  }

  return (
    <PostsSC ref={postsRef}>
      {post && (
        <Modal handleClose={handleClose}>
          <Post item={post} />
        </Modal>
      )}
      <span className="navigation">{`${totalCount} article${
        totalCount > 1 ? 's' : ''
      }`}</span>

      <ul>
        {posts &&
          posts.map(data => (
            <Item key={objectHash(data)} data={data} onClick={handleOnClick} />
          ))}
      </ul>
    </PostsSC>
  );
};

export default Posts;
