import styled from 'styled-components';

const Button = styled.button`
  appearance: none !important;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  .text {
    display: none;
  }

  .icon {
    background-color: ${props => (props.theme.mobile ? '#000' : '#fff')};
    width: 26px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
  }
`;

const ButtonClose = styled(Button)`
  top: 7px;
  left: auto;
  right: 7px;

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    height: 48px;
    width: 48px;
    top: 0;
    right: 0;
  }
`;

const ButtonPrev = styled(Button)`
  top: calc((100vh - 50px) / 2);
  left: 7px;
  display: block;

  .icon {
    transform: rotate(180deg);
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    display: none;
  }
`;

const ButtonNext = styled(Button)`
  top: calc((100vh - 50px) / 2);
  left: auto;
  right: 7px;
  display: block;

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    display: none;
  }
`;

const Content = styled.div`
  :before {
    content: '';
    display: block;
    flex-basis: 40px;
    flex-shrink: 0;
  }

  :after {
    content: '';
    display: block;
    flex-basis: 40px;
    flex-shrink: 0;
  }

  padding: 0 40px;
  pointer-events: none;
  transform: translate3d(0, 0, 0);

  display: flex;
  flex-direction: column;
  min-height: 100%;

  width: auto;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: ${props => (props.theme.mobile ? '100px' : 0)};

  div.modal-content {
    background: #ffffff;
    align-items: center;
    margin: auto;
    max-width: 935px;
    pointer-events: auto;
    width: 100%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    :before,
    :after {
      display: none;
    }
    padding: 0;
    div.modal-content {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const Modal = styled.div`
  //top: 0;
  //left: 0;
  //height: 100%;

  bottom: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  > div.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export { ButtonClose, ButtonNext, ButtonPrev, Content, Modal };
