import { ArticleNode } from '@innedit/innedit-type';
import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../components/Content';
import Layout from '../components/Layout';
import Posts from '../components/Posts';

const List: FC<
  PageProps<{
    items: {
      edges: { node: ArticleNode }[];
      totalCount: number;
    };
  }>
> = ({
  data: {
    items: { edges, totalCount },
  },
  path,
}) => {
  const items = edges.map(edge => edge.node);

  return (
    <Layout pathname={path} title="Les actualités du club">
      <Content>
        <Posts items={items} pathname={path} totalCount={totalCount} />
      </Content>
    </Layout>
  );
};

List.propTypes = {};

export default List;

export const query = graphql`
  query ArticlesList($offset: Int!, $limit: Int!) {
    items: allArticle(
      limit: $limit
      skip: $offset
      sort: { fields: createdAt, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          contents {
            id
            medias {
              origin
              pathname
            }
            mediasOptions
            order
            text
          }
          name
          title
        }
      }
    }
  }
`;
