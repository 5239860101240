import { Markdown } from '@innedit/innedit-react';
import { ArticleNode } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC } from 'react';

const Post: FC<{
  item: ArticleNode;
}> = function ({ item }) {
  const { contents } = item;

  return (
    <div>
      <h1>{item.name}</h1>

      {contents &&
        contents.length > 0 &&
        contents.map(content => {
          if (!content.text) {
            return null;
          }

          return (
            <Markdown key={objectHash(content)} refs={{}} text={content.text} />
          );
        })}
    </div>
  );
};

export default Post;
